import { CLEAR_PRODUCT_ERROR, CLOSE_CREATE_PRODUCT_MODAL, CLOSE_UPDATE_PRODUCT_MODAL, CREATE_PRODUCT_ERROR, 
    CREATE_PRODUCT_PENDING, CREATE_PRODUCT_SUCCESS, GET_PRODUCTS_PENDING, OPEN_CREATE_PRODUCT_MODAL, 
    OPEN_UPDATE_PRODUCT_MODAL, SET_ASSIGNABLE_PRODUCTS_LIST_ASYNC, SET_PRODUCTS_LIST, SET_PRODUCT_DETAILS, UPDATE_PRODUCT_ERROR, UPDATE_PRODUCT_PENDING, 
    UPDATE_PRODUCT_SUCCESS } from "../constants/product.constants";


const initialState: Any = {
  isCreateProductModalOpen: false,
  isUpdateProductModalOpen: false,
  isGetProductsPending: false,
  products: [],
  productDetails: {},
  isCreatePending: false,
  isUpdatePending: false,
  errorMessage: null
};

export default (
  localState: Any = initialState,
  action: Action
): ApprovalState => {
  switch (action.type) {
    case CREATE_PRODUCT_SUCCESS: {
      return {
        ...localState,
        isCreatePending: false,
      };
    }
    case CREATE_PRODUCT_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isCreatePending: false,
      };
    }
    case CREATE_PRODUCT_PENDING: {
      return {
        ...localState,
        isCreatePending: true,
      };
    }
    case UPDATE_PRODUCT_SUCCESS: {
        return {
          ...localState,
          isUpdatePending: false,
        };
      }
      case UPDATE_PRODUCT_ERROR: {
        const { errorMessage } = action.payload;
        return {
          ...localState,
          errorMessage,
          isUpdatePending: false,
        };
      }
      case UPDATE_PRODUCT_PENDING: {
        return {
          ...localState,
          isUpdatePending: true,
        };
      }
    case CLEAR_PRODUCT_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case OPEN_CREATE_PRODUCT_MODAL: {
      return {
        ...localState,
        isCreateProductModalOpen: true,
        errorMessage: null,
      };
    }
    case CLOSE_CREATE_PRODUCT_MODAL: {
      return {
        ...localState,
        isCreateProductModalOpen: false,
      };
    }
    case OPEN_UPDATE_PRODUCT_MODAL: {
        return {
          ...localState,
          isUpdateProductModalOpen: true,
          errorMessage: null,
        };
      }
      case CLOSE_UPDATE_PRODUCT_MODAL: {
        return {
          ...localState,
          isUpdateProductModalOpen: false,
        };
      }
    case GET_PRODUCTS_PENDING: {
      return {
        ...localState,
        isGetProductsPending: true,
      };
    }
    case SET_PRODUCTS_LIST: {
      const { productsOdata } = action.payload;
      return {
        ...localState,
        isGetProductsPending: false,
        products: productsOdata && productsOdata.value,
      };
    }
    case SET_PRODUCT_DETAILS: {
      return {
        ...localState,
        productDetails: action.payload
      }
    }
    case SET_ASSIGNABLE_PRODUCTS_LIST_ASYNC: {
      const { productsListAsync,  searchValueAsync} = action.payload;
      return{
        ...localState,
        productsListAsync: productsListAsync,
        searchValueAsync
      }
    }
    default: {
      return localState;
    }
  }
};
