import type { LoginState } from "../../types/login";
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_PENDING,
  LOGIN_PENDING_SUCCESS,
  LOGIN_PENDING_ERROR,
  CLEAR_LOGIN_ERROR,
  CLEAR_ENABLE_USER_ERROR,
  ENABLE_USER_PENDING_SUCCESS,
  ENABLE_USER_PENDING,
  ENABLE_USER_ERROR,
  ENABLE_USER_PENDING_ERROR,
} from "../constants/login.constants";

const initialState: LoginState = {
  isAuth: false,
  token: null,
  expires: null,
  email: null,
  errorMessage: null,
  isPending: false,
  isEnableUserPending: false,
  enableUserErrorMessage: null,
};

export default (
  localState: LoginState = initialState,
  action: Action
): LoginState => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const { email, token, expires } = action.payload;
      return {
        ...localState,
        email,
        token,
        expires,
        isAuth: true,
        errorMessage: null,
        isPending: false,
      };
    }
    case LOGIN_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case LOGIN_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case LOGIN_PENDING_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case LOGIN_PENDING_ERROR: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case CLEAR_LOGIN_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case ENABLE_USER_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        enableUserErrorMessage: errorMessage,
        isEnableUserPending: false,
      };
    }
    case ENABLE_USER_PENDING: {
      return {
        ...localState,
        isEnableUserPending: true,
      };
    }
    case ENABLE_USER_PENDING_ERROR: {
      return {
        ...localState,
        isEnableUserPending: false,
      };
    }
    case ENABLE_USER_PENDING_SUCCESS: {
      return {
        ...localState,
        isEnableUserPending: false,
      };
    }
    case CLEAR_ENABLE_USER_ERROR: {
      return {
        ...localState,
        enableUserErrorMessage: null
      };
    }
    default: {
      return localState;
    }
  }
};
