import { ApprovalState } from "../../types/approval";
import {
  CLEAR_SUBMIT_APPROVAL_ACTION_ERROR,
  CLOSE_APPROVAL_ACTION_MODAL,
  OPEN_APPROVAL_ACTION_MODAL,
  SET_APPROVALS_LIST,
  SET_APPROVALS_PENDING,
  SUBMIT_APPROVAL_ACTION_ERROR,
  SUBMIT_APPROVAL_ACTION_PENDING,
  SUBMIT_APPROVAL_ACTION_SUCCESS,
} from "../constants/approval.constants";

const initialState: ApprovalState = {
  isApprovalActionModalOpen: false,
  isGetApprovalsPending: false,
  approvals: [],
  isPending: false,
  errorMessage: null
};

export default (
  localState: ApprovalState = initialState,
  action: Action
): ApprovalState => {
  switch (action.type) {
    case SUBMIT_APPROVAL_ACTION_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case SUBMIT_APPROVAL_ACTION_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case SUBMIT_APPROVAL_ACTION_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_SUBMIT_APPROVAL_ACTION_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case OPEN_APPROVAL_ACTION_MODAL: {
      return {
        ...localState,
        isApprovalActionModalOpen: true,
        errorMessage: null,
      };
    }
    case CLOSE_APPROVAL_ACTION_MODAL: {
      return {
        ...localState,
        isApprovalActionModalOpen: false,
      };
    }
    case SET_APPROVALS_PENDING: {
      return {
        ...localState,
        isGetApprovalsPending: true,
      };
    }
    case SET_APPROVALS_LIST: {
      const { approvalsOdata } = action.payload;
      return {
        ...localState,
        isGetApprovalsPending: false,
        approvals: approvalsOdata && approvalsOdata.value,
      };
    }
    default: {
      return localState;
    }
  }
};
