export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_ERROR = "CREATE_PRODUCT_ERROR";
export const CREATE_PRODUCT_PENDING = "CREATE_PRODUCT_PENDING";
export const CLEAR_PRODUCT_ERROR = "CLEAR_PRODUCT_ERROR";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const OPEN_CREATE_PRODUCT_MODAL = "OPEN_CREATE_PRODUCT_MODAL";
export const CLOSE_CREATE_PRODUCT_MODAL = "CLOSE_CREATE_PRODUCT_MODAL";
export const OPEN_UPDATE_PRODUCT_MODAL = "OPEN_UPDATE_PRODUCT_MODAL";
export const CLOSE_UPDATE_PRODUCT_MODAL = "CLOSE_UPDATE_PRODUCT_MODAL";
export const GET_PRODUCTS_PENDING = "GET_PRODUCTS_PENDING";
export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_ERROR";
export const UPDATE_PRODUCT_PENDING = "UPDATE_PRODUCT_PENDING";
export const SET_PRODUCT_DETAILS = "SET_PRODUCT_DETAILS";
export const SET_ASSIGNABLE_PRODUCTS_LIST_ASYNC = "SET_ASSIGNABLE_PRODUCTS_LIST_ASYNC";
