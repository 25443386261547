import { DeviceState } from "../../types/device";
import {
  ASSIGN_DEVICE_ERROR,
  ASSIGN_DEVICE_PENDING,
  ASSIGN_DEVICE_SUCCESS,
  CLEAR_ASSIGN_DEVICE_ERROR,
  CLEAR_CREATE_MASTER_DEVICE_ERROR,
  CLEAR_DEVICE_ERROR,
  CLOSE_ASSIGN_DEVICE_MODAL,
  CLOSE_CREATE_DEVICE_MODAL,
  CLOSE_CREATE_MASTER_DEVICE_MODAL,
  CREATE_DEVICE_ERROR,
  CREATE_DEVICE_PENDING,
  CREATE_DEVICE_SUCCESS,
  CREATE_MASTER_DEVICE_ERROR,
  CREATE_MASTER_DEVICE_PENDING,
  CREATE_MASTER_DEVICE_SUCCESS,
  OPEN_ASSIGN_DEVICE_MODAL,
  OPEN_CREATE_DEVICE_MODAL,
  OPEN_CREATE_MASTER_DEVICE_MODAL,
  SET_CURRENT_USER_UNASSIGNED_DEVICE_LIST_ASYNC,
  SET_DEVICES_LIST,
  SET_DEVICES_PENDING,
  SET_INITIAL_STATE,
  SET_LAST_SERIAL_NO,
  SET_MASTER_DEVICES_LIST,
  SET_MASTER_DEVICES_PENDING,
  SET_MASTER_DEVICE_KEY,
  SET_UNASSIGNABLE_GROUPS_LIST_ASYNC,
  SET_UNASSIGNED_DEVICE_LIST_ASYNC,
} from "../constants/device.constants";

const initialState: DeviceState = {
  isCreateDeviceModalOpen: false,
  devices: [],
  errorMessage: "",
  isPending: false,
  isGetDevicePending: false,
  isAssignDeviceModalOpen: false,
  isAssignPending: false,
  isCreateMasterDeviceModalOpen: false,
  isCreateMasterDevicePending: false,
  masterDeviceKey: "",
  isGetMasterDevicesPending: false,
  masterDevices:[],
  unAssignedDevicesListAsync: [],
  currentUserUnAssignedDevicesListAsync: [],
  groupsListAsync: [],
  searchValueAsync:"",
  totalMasterDeviceRecords: 0,
  totalDeviceRecords: 0
};

export default (
  localState: DeviceState = initialState,
  action: Action
): DeviceState => {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return {
        ...initialState,
        isCreateDeviceModalOpen: true,
      };
    }
    case OPEN_CREATE_DEVICE_MODAL: {
      return {
        ...localState,
        isCreateDeviceModalOpen: true,
      };
    }
    case CLOSE_CREATE_DEVICE_MODAL: {
      return {
        ...localState,
        isCreateDeviceModalOpen: false,
        errorMessage: null,
      };
    }
    case CREATE_DEVICE_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case CREATE_DEVICE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_DEVICE_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_DEVICE_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case SET_DEVICES_PENDING: {
      return {
        ...localState,
        isGetDevicePending: true,
      };
    }
    case SET_DEVICES_LIST: {
      const { devicesOdata } = action.payload;
      return {
        ...localState,
        isGetDevicePending: false,
        devices: devicesOdata && devicesOdata['value'],
        totalDeviceRecordsCount: devicesOdata["@odata.count"],
      };
    }
    case OPEN_ASSIGN_DEVICE_MODAL: {
      return {
        ...localState,
        isAssignDeviceModalOpen: true,
      };
    }
    case CLOSE_ASSIGN_DEVICE_MODAL: {
      return {
        ...localState,
        isAssignDeviceModalOpen: false,
        errorMessage: null
      };
    }
    case ASSIGN_DEVICE_SUCCESS: {
      return {
        ...localState,
        isAssignPending: false,
      };
    }
    case ASSIGN_DEVICE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isAssignPending: false,
      };
    }
    case ASSIGN_DEVICE_PENDING: {
      return {
        ...localState,
        isAssignPending: true,
      };
    }
    case CLEAR_ASSIGN_DEVICE_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case OPEN_CREATE_MASTER_DEVICE_MODAL: {
      return {
        ...localState,
        isCreateMasterDeviceModalOpen: true,
      };
    }
    case CLOSE_CREATE_MASTER_DEVICE_MODAL: {
      return {
        ...localState,
        isCreateMasterDeviceModalOpen: false,
        errorMessage: null,
      };
    }
    case CREATE_MASTER_DEVICE_SUCCESS: {
      return {
        ...localState,
        isCreateMasterDevicePending: false,
      };
    }
    case CREATE_MASTER_DEVICE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isCreateMasterDevicePending: false,
      };
    }
    case CREATE_MASTER_DEVICE_PENDING: {
      return {
        ...localState,
        isCreateMasterDevicePending: true,
      };
    }
    case CLEAR_CREATE_MASTER_DEVICE_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case SET_MASTER_DEVICE_KEY:{
      return{
        ...localState,
        masterDeviceKey: action.payload
      }
    }
    case SET_MASTER_DEVICES_PENDING: {
      return {
        ...localState,
        isGetMasterDevicesPending: true,
      };
    }
    case SET_MASTER_DEVICES_LIST: {
      const { masterDevicesOdata } = action.payload;
      return {
        ...localState,
        isGetMasterDevicesPending: false,
        masterDevices: masterDevicesOdata && masterDevicesOdata['value'],
        totalMasterDeviceRecordsCount: masterDevicesOdata["@odata.count"],
      };
    }
    case SET_UNASSIGNED_DEVICE_LIST_ASYNC: {
      const { devicesListAsync,  searchValueAsync} = action.payload;
      return {
        ...localState,
        unAssignedDevicesListAsync: devicesListAsync,
        searchValueAsync
      };
    }
    case SET_UNASSIGNABLE_GROUPS_LIST_ASYNC: {
      const { groupsListAsync,  searchValueAsync} = action.payload;
      return {
        ...localState,
        groupsListAsync: groupsListAsync,
        searchValueAsync
      };
    }
    case SET_LAST_SERIAL_NO: {
      return {
        ...localState,
        lastSerialNo: action.payload
      };
    }
    case SET_CURRENT_USER_UNASSIGNED_DEVICE_LIST_ASYNC: {
      const { devicesListAsync,  searchValueAsync} = action.payload;
      return {
        ...localState,
        currentUserUnAssignedDevicesListAsync: devicesListAsync,
        searchValueAsync
      };
    }
    default: {
      return localState;
    }
  }
};
