import { GET_REPORT_DATA_ERROR, GET_REPORT_DATA_PENDING, GET_REPORT_DATA_SUCCESS, SET_REPORT_DATA } from "../constants/dashboard.constants";

const initialState: any = {
  isPending: false,
  reportData: null,
};

export default (localState: any = initialState, action: Action): any => {
  switch (action.type) {
    case GET_REPORT_DATA_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case GET_REPORT_DATA_ERROR: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case GET_REPORT_DATA_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case SET_REPORT_DATA: {
      return {
        ...localState,
        reportData: action.payload,
      };
    }
    default: {
      return localState;
    }
  }
};
