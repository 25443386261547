import type { ProfileState } from "../../types/profile";
import { SET_CURRENT_PROFILE, SET_FORGOT_PASSWORD_CONFIRMATION_STATUS } from "../constants/profile.constants";

const initialState: ProfileState = {
  userName: "",
  email: "",
  currentUserRoles: [],
  currentProfileRole: null,
  confirmationStatus: ""
};

export default (
  localState: ProfileState = initialState,
  action: Action
): ProfileState => {
  switch (action.type) {
    case SET_CURRENT_PROFILE: {
      const { userName, email, roles, userNotification } = action.payload;
      return {
        ...localState,
        userName,
        email,
        currentUserRoles: roles,
        currentProfileRole: roles[0],
        userNotification: userNotification
      };
    }
    case SET_FORGOT_PASSWORD_CONFIRMATION_STATUS: {
      return {
        ...localState,
        confirmationStatus: action.payload
      }
    }
    default: {
      return localState;
    }
  }
};
