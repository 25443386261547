export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const CREATE_USER_PENDING = "CREATE_USER_PENDING";
export const CLEAR_USER_ERROR = "CLEAR_USER_ERROR";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const OPEN_CREATE_USER_MODAL = "OPEN_CREATE_USER_MODAL";
export const CLOSE_CREATE_USER_MODAL = "CLOSE_CREATE_USER_MODAL";
export const SET_USERS_PENDING = "SET_USERS_PENDING";
export const SET_USERS_LIST = "SET_USERS_LIST";
export const SET_USERS_LIST_ASYNC = "SET_USERS_LIST_ASYNC";
export const SET_USER_DETAILS_INITIAL_STATE = "SET_USER_DETAILS_INITIAL_STATE";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const GET_USER_DETAILS_PENDING = "GET_USER_DETAILS_PENDING";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const SEND_RESET_PASSWORD_PENDING = "SEND_RESET_PASSWORD_PENDING";
export const SEND_RESET_PASSWORD_SUCCESS = "SEND_RESET_PASSWORD_SUCCESS";
export const SEND_RESET_PASSWORD_ERROR = "SEND_RESET_PASSWORD_ERROR";
export const OPEN_CHANGE_PASSWORD_MODAL = "OPEN_CHANGE_PASSWORD_MODAL";
export const CLOSE_CHANGE_PASSWORD_MODAL = "CLOSE_CHANGE_PASSWORD_MODAL";
export const CHANGE_PASSWORD_PENDING = "CHANGE_PASSWORD_PENDING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CLEAR_CHANGE_PASSWORD_ERROR = "CLEAR_CHANGE_PASSWORD_ERROR";
export const CLOSE_EDIT_USER_MODAL = "CLOSE_EDIT_USER_MODAL";
export const OPEN_EDIT_USER_MODAL = "OPEN_EDIT_USER_MODAL";
export const UPDATE_USER_PENDING = "UPDATE_USER_PENDING";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const CLEAR_UPDATE_USER_ERROR = "CLEAR_UPDATE_USER_ERROR";
export const SET_RETAILERS_LIST_ASYNC = "SET_RETAILERS_LIST_ASYNC";
export const SET_FILTERED_STATES_LIST_ASYNC = "SET_FILTERED_STATES_LIST_ASYNC";
export const CLOSE_TRANSFER_DEVICE_MODAL = "CLOSE_TRANSFER_DEVICE_MODAL";
export const OPEN_TRANSFER_DEVICE_MODAL = "OPEN_TRANSFER_DEVICE_MODAL";
export const TRANSFER_DEVICE_PENDING = "TRANSFER_DEVICE_PENDING";
export const TRANSFER_DEVICE_SUCCESS = "TRANSFER_DEVICE_SUCCESS";
export const TRANSFER_DEVICE_ERROR = "TRANSFER_DEVICE_ERROR";
export const CLEAR_TRANSFER_DEVICE_ERROR = "CLEAR_TRANSFER_DEVICE_ERROR";
export const SET_ASSIGNABLE_TRANSFER_USERS_LIST_ASYNC = "SET_ASSIGNABLE_TRANSFER_USERS_LIST_ASYNC";
export const SET_SUBORDINATES_USERS_LIST_ASYNC = "SET_SUBORDINATES_USERS_LIST_ASYNC";
export const TRANSFER_USER_PENDING = "TRANSFER_USER_PENDING";
export const TRANSFER_USER_SUCCESS = "TRANSFER_USER_SUCCESS";
export const TRANSFER_USER_ERROR = "TRANSFER_USER_ERROR";
export const CLEAR_TRANSFER_USER_ERROR = "CLEAR_TRANSFER_USER_ERROR";


export const USER_EXPORT_HEADERS = [
    "NAME",
    "EMAIL",
    "ROLE",
    "MOBILE NUMBER",
    "MANAGED BY",
    "MANAGED BY EMAIL",
    "TOTAL ASSIGNED DEVICES",
    "AVAILABLE DEVICES",
    "STATUS",
    "ENABLED",
  ];
