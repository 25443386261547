import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';
import formReducer from './form.reducer.js'
import loginReducer from './login.reducer.js';
import profileReducer from './profile.reducer.js';
import organisationReducer from './organisation.reducer.js';
import snackbarReducer from './snackbar.reducer.js';
import userReducer from './user.reducer.js';
import deviceReducer from './device.reducer.js';
import approvalReducer from './approval.reducer';
import requestReducer from './request.reducer.js';
import organisationDetailsReducer from './organisationDetails.reducer.js';
import userDetailsReducer from './userDetails.reducer.js';
import deviceDetailsReducer from './deviceDetails.reducer.js';
import dashboardReducer from './dashboard.reducer.js';
import productReducer from './product.reducer.js';
import zoneReducer from './zone.reducer.js';

export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    form: formReducer,
    login: loginReducer,
    profile: profileReducer,
    organisation: organisationReducer,
    snackbar: snackbarReducer,
    user: userReducer,
    device: deviceReducer,
    approval: approvalReducer,
    request: requestReducer,
    organisationDetails: organisationDetailsReducer,
    userDetails: userDetailsReducer,
    deviceDetails: deviceDetailsReducer,
    dashboard: dashboardReducer,
    product: productReducer,
    zone: zoneReducer
});
