export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const OPEN_CREATE_DEVICE_MODAL = "OPEN_CREATE_DEVICE_MODAL";
export const CLOSE_CREATE_DEVICE_MODAL = "CLOSE_CREATE_DEVICE_MODAL";
export const CREATE_DEVICE_PENDING = "CREATE_DEVICE_PENDING";
export const CREATE_DEVICE_SUCCESS = "CREATE_DEVICE_SUCCESS";
export const CREATE_DEVICE_ERROR = "CREATE_DEVICE_ERROR";
export const CLEAR_DEVICE_ERROR = "CLEAR_DEVICE_ERROR";
export const SET_DEVICES_PENDING = "SET_DEVICES_PENDING";
export const SET_DEVICES_LIST = "SET_DEVICES_LIST";
export const OPEN_ASSIGN_DEVICE_MODAL = "OPEN_ASSIGN_DEVICE_MODAL";
export const CLOSE_ASSIGN_DEVICE_MODAL = "CLOSE_ASSIGN_DEVICE_MODAL";
export const ASSIGN_DEVICE_PENDING = "ASSIGN_DEVICE_PENDING";
export const ASSIGN_DEVICE_SUCCESS = "ASSIGN_DEVICE_SUCCESS";
export const ASSIGN_DEVICE_ERROR = "ASSIGN_DEVICE_ERROR";
export const CLEAR_ASSIGN_DEVICE_ERROR = "CLEAR_ASSIGN_DEVICE_ERROR";
export const OPEN_CREATE_MASTER_DEVICE_MODAL =
  "OPEN_CREATE_MASTER_DEVICE_MODAL";
export const CLOSE_CREATE_MASTER_DEVICE_MODAL =
  "CLOSE_CREATE_MASTER_DEVICE_MODAL";
export const CREATE_MASTER_DEVICE_PENDING = "CREATE_MASTER_DEVICE_PENDING";
export const CREATE_MASTER_DEVICE_SUCCESS = "CREATE_MASTER_DEVICE_SUCCESS";
export const CREATE_MASTER_DEVICE_ERROR = "CREATE_MASTER_DEVICE_ERROR";
export const CLEAR_CREATE_MASTER_DEVICE_ERROR =
  "CLEAR_CREATE_MASTER_DEVICE_ERROR";
export const SET_MASTER_DEVICE_KEY = "SET_MASTER_DEVICE_KEY";
export const SET_MASTER_DEVICES_PENDING = "SET_MASTER_DEVICES_PENDING";
export const SET_MASTER_DEVICES_LIST = "SET_MASTER_DEVICES_LIST";
export const SET_DEVICE_DETAILS_INITIAL_STATE =
  "SET_DEVICE_DETAILS_INITIAL_STATE";
export const GET_DEVICE_DETAILS_PENDING = "GET_DEVICE_DETAILS_PENDING";
export const SET_DEVICE_DETAILS = "SET_DEVICE_DETAILS";
export const GET_DEVICE_DETAILS_SUCCESS = "GET_DEVICE_DETAILS_SUCCESS";
export const CLOSE_EDIT_DEVICE_MODAL = "CLOSE_EDIT_DEVICE_MODAL";
export const OPEN_EDIT_DEVICE_MODAL = "OPEN_EDIT_DEVICE_MODAL";
export const CLEAR_UPDATE_DEVICE_ERROR = "CLEAR_UPDATE_DEVICE_ERROR";
export const UPDATE_DEVICE_PENDING = "UPDATE_DEVICE_PENDING";
export const UPDATE_DEVICE_SUCCESS = "UPDATE_DEVICE_SUCCESS";
export const UPDATE_DEVICE_ERROR = "UPDATE_DEVICE_ERROR";
export const GET_MASTER_DEVICE_DETAILS_PENDING = "GET_MASTER_DEVICE_DETAILS_PENDING";
export const SET_MASTER_DEVICE_DETAILS = "SET_MASTER_DEVICE_DETAILS";
export const GET_MASTER_DEVICE_DETAILS_SUCCESS = "GET_MASTER_DEVICE_DETAILS_SUCCESS";
export const CLOSE_EDIT_MASTER_DEVICE_MODAL = "CLOSE_EDIT_MASTER_DEVICE_MODAL";
export const OPEN_EDIT_MASTER_DEVICE_MODAL = "OPEN_EDIT_MASTER_DEVICE_MODAL";
export const CLEAR_UPDATE_MASTER_DEVICE_ERROR = "CLEAR_UPDATE_MASTER_DEVICE_ERROR";
export const UPDATE_MASTER_DEVICE_PENDING = "UPDATE_MASTER_DEVICE_PENDING";
export const UPDATE_MASTER_DEVICE_SUCCESS = "UPDATE_MASTER_DEVICE_SUCCESS";
export const UPDATE_MASTER_DEVICE_ERROR = "UPDATE_MASTER_DEVICE_ERROR";
export const CLOSE_MAP_NEW_DEVICE_MODAL = "CLOSE_MAP_NEW_DEVICE_MODAL";
export const OPEN_MAP_NEW_DEVICE_MODAL = "OPEN_MAP_NEW_DEVICE_MODAL";
export const CLEAR_MAP_NEW_DEVICE_ERROR = "CLEAR_MAP_NEW_DEVICE_ERROR";
export const MAP_NEW_DEVICE_PENDING = "MAP_NEW_DEVICE_PENDING";
export const MAP_NEW_DEVICE_SUCCESS = "MAP_NEW_DEVICE_SUCCESS";
export const MAP_NEW_DEVICE_ERROR = "MAP_NEW_DEVICE_ERROR";
export const CLOSE_UPDATE_ROOM_DETAILS_MODAL = "CLOSE_UPDATE_ROOM_DETAILS_MODAL";
export const OPEN_UPDATE_ROOM_DETAILS_MODAL = "OPEN_UPDATE_ROOM_DETAILS_MODAL";
export const CLEAR_UPDATE_ROOM_DETAILS_ERROR = "CLEAR_UPDATE_ROOM_DETAILS_ERROR";
export const UPDATE_ROOM_DETAILS_PENDING = "UPDATE_ROOM_DETAILS_PENDING";
export const UPDATE_ROOM_DETAILS_SUCCESS = "UPDATE_ROOM_DETAILS_SUCCESS";
export const UPDATE_ROOM_DETAILS_ERROR = "UPDATE_ROOM_DETAILS_ERROR";
export const SET_UNASSIGNED_DEVICE_LIST_ASYNC = "SET_UNASSIGNED_DEVICE_LIST_ASYNC";
export const SET_UNASSIGNABLE_GROUPS_LIST_ASYNC = "SET_UNASSIGNABLE_GROUPS_LIST_ASYNC";
export const OPEN_CREATE_GROUP_MODAL = "OPEN_CREATE_GROUP_MODAL";
export const CLOSE_CREATE_GROUP_MODAL = "CLOSE_CREATE_GROUP_MODAL";
export const CLEAR_CREATE_GROUP_ERROR = "CLEAR_CREATE_GROUP_ERROR";
export const CREATE_GROUP_PENDING = "CREATE_GROUP_PENDING";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR";
export const UPDATE_SELECTED_DEVICE_SWITCHLIST = "UPDATE_SELECTED_DEVICE_SWITCHLIST";
export const UPDATE_SWITCH_DETAILS = "UPDATE_SWITCH_DETAILS";
export const OPEN_UPDATE_GROUP_MODAL = "OPEN_UPDATE_GROUP_MODAL";
export const CLOSE_UPDATE_GROUP_MODAL = "CLOSE_UPDATE_GROUP_MODAL";
export const CLEAR_UPDATE_GROUP_ERROR = "CLEAR_UPDATE_GROUP_ERROR";
export const UPDATE_GROUP_PENDING = "UPDATE_GROUP_PENDING";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";
export const SET_LAST_SERIAL_NO = "SET_LAST_SERIAL_NO";
export const SET_CURRENT_USER_UNASSIGNED_DEVICE_LIST_ASYNC = "SET_CURRENT_USER_UNASSIGNED_DEVICE_LIST_ASYNC";


export const NO_OF_SWITCHES = 10;
export const DEFAULT_MASTER_DEVICE_SERIAL_NO = '0001';


export const ASSIGNED_STATUS_LIST = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "Assigned",
    value: true,
  },
  {
    label: "Unassigned",
    value: false,
  },
];

export const STATUS_LIST = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "Active",
    value: true,
  },
  {
    label: "Inactive",
    value: false,
  },
];

export const ENABLED_STATUS_LIST = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "Enabled",
    value: true,
  },
  {
    label: "Disbled",
    value: false,
  },
];


export const DEVICE_EXPORT_HEADERS = [
  "SERIAL #",
  "NAME",
  "H/W VER",
  "F/W VER",
  "ORGANISATION",
  "ASSIGN STATUS",
  "ASSIGNED USER",
  "ASSIGNED USER EMAIL",
  "STATUS",
  "ROOM NAME",
  "CUSTOMER NAME",
  "CUSTOMER EMAIL",
];

export const MASTER_DEVICE_EXPORT_HEADERS = [
  "UID",
  "STATUS",
  "DEVICES",
  "CUSTOMER NAME",
  "CUSTOMER EMAIL",
  "CUSTOMER ZONE",
  "CUSTOMER ZIP",
  "VALIDITY",
];