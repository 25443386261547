import {
    CLEAR_ZONE_ERROR,
  CLOSE_CREATE_ZONE_MODAL,
  CLOSE_UPDATE_ZONE_MODAL,
  CREATE_ZONE_ERROR,
  CREATE_ZONE_PENDING,
  CREATE_ZONE_SUCCESS,
  GET_ZONES_PENDING,
  OPEN_CREATE_ZONE_MODAL,
  OPEN_UPDATE_ZONE_MODAL,
  SET_ASSIGNABLE_ZONES_LIST_ASYNC,
  SET_ZONES_LIST,
  SET_ZONE_DETAILS,
  UPDATE_ZONE_ERROR,
  UPDATE_ZONE_PENDING,
  UPDATE_ZONE_SUCCESS,
} from "../constants/zone.constants";

const initialState: Any = {
  isCreateZoneModalOpen: false,
  isUpdateZoneModalOpen: false,
  isGetZonesPending: false,
  zones: [],
  zoneDetails: {},
  isCreatePending: false,
  isUpdatePending: false,
  errorMessage: null,
};

export default (
  localState: Any = initialState,
  action: Action
): ApprovalState => {
  switch (action.type) {
    case CREATE_ZONE_SUCCESS: {
      return {
        ...localState,
        isCreatePending: false,
      };
    }
    case CREATE_ZONE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isCreatePending: false,
      };
    }
    case CREATE_ZONE_PENDING: {
      return {
        ...localState,
        isCreatePending: true,
      };
    }
    case UPDATE_ZONE_SUCCESS: {
      return {
        ...localState,
        isUpdatePending: false,
      };
    }
    case UPDATE_ZONE_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isUpdatePending: false,
      };
    }
    case UPDATE_ZONE_PENDING: {
      return {
        ...localState,
        isUpdatePending: true,
      };
    }
    case CLEAR_ZONE_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case OPEN_CREATE_ZONE_MODAL: {
      return {
        ...localState,
        isCreateZoneModalOpen: true,
        errorMessage: null,
      };
    }
    case CLOSE_CREATE_ZONE_MODAL: {
      return {
        ...localState,
        isCreateZoneModalOpen: false,
      };
    }
    case OPEN_UPDATE_ZONE_MODAL: {
      return {
        ...localState,
        isUpdateZoneModalOpen: true,
        errorMessage: null,
      };
    }
    case CLOSE_UPDATE_ZONE_MODAL: {
      return {
        ...localState,
        isUpdateZoneModalOpen: false,
      };
    }
    case GET_ZONES_PENDING: {
      return {
        ...localState,
        isGetZonesPending: true,
      };
    }
    case SET_ZONES_LIST: {
      const { zonesOdata } = action.payload;
      return {
        ...localState,
        isGetZonesPending: false,
        zones: zonesOdata && zonesOdata.value,
      };
    }
    case SET_ZONE_DETAILS: {
      return {
        ...localState,
        zoneDetails: action.payload
      }
    }
    case SET_ASSIGNABLE_ZONES_LIST_ASYNC: {
      const { zonesListAsync,  searchValueAsync} = action.payload;
      return{
        ...localState,
        zonesListAsync: zonesListAsync,
        searchValueAsync
      }
    }
    default: {
      return localState;
    }
  }
};
