import superagentPromise from "superagent-promise";
import _superagent from "superagent";
import configureStore from "../store/store";
import { logoutUser } from "../store/actions/actions";

const superagent = superagentPromise(_superagent, Promise);

const responseBody = (res) => res.body;
//const apiUrl = "https://api.ecswitchserver.com";
// const apiUrl = "http://localhost:9209";
//const apiUrl = "http://ecs-api.visionwaretechnologies.com";
const apiUrl = "https://api.ecswitchserver.com";

const handleErrors = (err) => {
  if (err && err.status === 401) {
    const store = configureStore();
    store.dispatch(logoutUser());
  }
};

const requests = {
  post: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .on("error", handleErrors)
      .then(responseBody),
  postData: (url, body) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/x-www-form-urlencoded")
      .on("error", handleErrors)
      .then(responseBody),
  getWithToken: (url, token) =>
    superagent
      .get(url)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  postWithToken: (url, body, token) =>
    superagent
      .post(url, body)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  deleteWithToken: (url, token) =>
    superagent
      .del(url, token)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
  putWithToken: (url, body, token) =>
    superagent
      .put(url, body, token)
      .set("Content-Type", "application/json")
      .set("Authorization", `Bearer ${token}`)
      .on("error", handleErrors)
      .then(responseBody),
};

const Auth = {
  login: (email: string, password: string) => {
    const formData =
      "username=" +
      encodeURIComponent(email) +
      "&password=" +
      encodeURIComponent(password) +
      "&grant_type=password";
    return requests.postData(`${apiUrl}/token`, formData);
  },
  getCurrentUser: (access_token: string) => {
    return requests.getWithToken(`${apiUrl}/api/user/profile`, access_token);
  },
  enableAccount: (data: any, code) => {
    const { password, confirmPassword } = data;
    return requests.postData(`${apiUrl}/api/user/enableuser`, {
      password,
      confirmPassword,
      code,
    });
  },
  forgotPassword: (email: string) => {
    return requests.postData(`${apiUrl}/api/user/forgotpassword`, {
      email,
    });
  },
  resetPassword: (data: any, code) => {
    const { password, confirmPassword, email } = data;
    return requests.postData(`${apiUrl}/api/user/resetpassword`, {
      password,
      confirmPassword,
      code,
      email,
    });
  },
};

const Organisation = {
  createOrganisation: (access_token: string, organisation: Object) => {
    const {
      name,
      pincode,
      addressLine1,
      addressLine2,
      city,
      country,
      state,
    } = organisation;
    return requests.postWithToken(
      `${apiUrl}/api/org/create`,
      {
        OrgName: name,
        pincode,
        addressLine1,
        addressLine2,
        city,
        country,
        state,
      },
      access_token
    );
  },
  getOrganisations: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/orglist?$count=true&$orderby=name asc`,
      access_token
    );
  },
  getOrganisationDetails: (access_token: String, organisationId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/org/details?orgId=${organisationId}`,
      access_token
    );
  },
  updateOrganisation: (access_token: string, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/org/update`,
      data,
      access_token
    );
  },
};

const User = {
  createUser: (access_token: string, user: Object) => {
    const {
      firstName,
      lastName,
      displayName,
      email,
      mobileNumber,
      new_user_organisation,
      new_retailer,
      new_user_role,
      isNotify,
      addressLine1,
      addressLine2,
      selected_zone,
      selected_state,
      town,
      zip,
      district,
    } = user;
    return requests.postWithToken(
      `${apiUrl}/api/user/create`,
      {
        firstName,
        lastName,
        displayName,
        email,
        mobileNumber,
        NotifyUser: isNotify,
        roleName: new_user_role && new_user_role.id,
        orgId: new_user_organisation && new_user_organisation.value,
        retailerId: new_retailer && new_retailer.value,
        addressLine1,
        addressLine2,
        zone: selected_zone && selected_zone.value,
        town,
        state: selected_state && selected_state.value,
        zip,
        district,
      },
      access_token
    );
  },
  getUsers: (access_token: string, currentPage: Number = 1, pageSize: Number = 100, url: string = "") => {
    return requests.getWithToken(
      `${apiUrl}/odata/userlist?$count=true${url}&$skip=${(currentPage - 1) * pageSize}&$top=${pageSize}`,
      access_token
    );
  },
  getAssignableUsers: (
    access_token: string,
    searchValue: string,
    organisationId: string
  ) => {
    return requests.getWithToken(
      `${apiUrl}/api/user/assignable?organisationId=${organisationId}&key=${searchValue}`,
      access_token
    );
  },
  getAssignableRetailers: (access_token: string, organisationId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/user/assignable/retailers?organisationId=${organisationId}`,
      access_token
    );
  },
  getCustomers: (access_token: string, organisationId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/user/customers?organisationId=${organisationId}`,
      access_token
    );
  },
  getUserDetails: (access_token: string, userId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/user/details?userId=${userId}`,
      access_token
    );
  },
  sendResetPasswordEmail: (email: string) => {
    return requests.post(`${apiUrl}/api/user/reactivate`, { email });
  },
  changePassword: (access_token: string, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/user/changepassword`,
      data,
      access_token
    );
  },
  updateUser: (access_token: string, data: any) => {
    return requests.postWithToken(
      `${apiUrl}/api/user/update`,
      data,
      access_token
    );
  },
  exportData: (access_token) => {
    return requests.getWithToken(
      `${apiUrl}/odata/userlist/export?$count=true`,
      access_token
    );
  },
  getFilteredStates: (access_token: string, searchValue: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/states?key=${searchValue}`,
      access_token
    );
  },
  getAssignableTransferUser: (access_token: string, userId: string, organisationId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/user/assignable/${userId}/${organisationId}`,
      access_token
    );
  },
  getSubordinates: (access_token: string, userId: string, organisationId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/user/subordinates/${userId}/${organisationId}`,
      access_token
    );
  },
  transferUsers: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/user/transfer`,
      data,
      access_token
    );
  }
};

const Request = {
  getRequests: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/requestList?$count=true&$orderby=displayName asc`,
      access_token
    );
  },
};

const Approval = {
  getApprovals: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/odata/approvalList?$count=true&$orderby=displayName asc`,
      access_token
    );
  },
  submitApproval: (access_token: string, approvalObject: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/user/submit`,
      approvalObject,
      access_token
    );
  },
};

const Device = {
  addDevices: (access_token: string, devices: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/device/add`,
      devices,
      access_token
    );
  },
  getDevices: (access_token: string, currentPage: Number = 1, pageSize: Number = 100, url: string = "") => {
    return requests.getWithToken(
      `${apiUrl}/odata/devicelist?$count=true${url}&$skip=${(currentPage - 1) * pageSize}&$top=${pageSize}`,
      access_token
    );
  },
  assignDevices: (access_token: string, deviceAssignObj: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/device/assign`,
      deviceAssignObj,
      access_token
    );
  },
  createMasterDevice: (access_token: string, masterDeviceObj: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/device/master/create`,
      masterDeviceObj,
      access_token
    );
  },
  createMasterDeviceKey: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/device/master-device-key`,
      access_token
    );
  },
  getMasterDevices: (access_token: string, currentPage: Number = 1, pageSize: Number = 100, url: string = "") => {
    return requests.getWithToken(
      `${apiUrl}/odata/masterdevicelist?$count=true${url}&$skip=${(currentPage - 1) * pageSize}&$top=${pageSize}`,
      access_token
    );
  },
  getDeviceDetails: (access_token: string, deviceId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/device/details?devId=${deviceId}`,
      access_token
    );
  },
  updateDevice: (access_token: string, device: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/device/update`,
      device,
      access_token
    );
  },
  getMasterDeviceDetails: (access_token: String, masterDeviceId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/device/master/details?masterDevId=${masterDeviceId}`,
      access_token
    );
  },
  updateMasterDevice: (access_token: string, device: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/device/master/update`,
      device,
      access_token
    );
  },
  mapNewDevice: (access_token: String, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/device/master/map-devices`,
      data,
      access_token
    );
  },
  updateRoomDetails: (access_token: String, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/device/update/room`,
      data,
      access_token
    );
  },
  removeMappedDevice: (
    access_token: String,
    masterDeviceId: string,
    deviceKey: string
  ) => {
    const data = {
      masterDeviceId: masterDeviceId,
      devicekey: deviceKey,
    };
    return requests.postWithToken(
      `${apiUrl}/api/device/master/remove-device`,
      data,
      access_token
    );
  },
  getUnAssignedDevices: (access_token: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/device/unassigned`,
      access_token
    );
  },
  getAssinableGroups: (access_token: string, masterDeviceId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/device/assignable/groups?masterDeviceId=${masterDeviceId}`,
      access_token
    );
  },
  createGroup: (access_token: String, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/device/group/create`,
      data,
      access_token
    );
  },
  updateGroup: (access_token: String, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/device/group/update`,
      data,
      access_token
    );
  },
  exportData: (access_token) => {
    return requests.getWithToken(
      `${apiUrl}/odata/devicelist/export`,
      access_token
    )
  },
  exportMasterData: (access_token) => {
    return requests.getWithToken(
      `${apiUrl}/odata/masterdevicelist/export`,
      access_token
    )
  },
  getLastSerialNumber: (access_token) => {
    return requests.getWithToken(
      `${apiUrl}/api/device/maxserialno`,
      access_token
    )
  },
  getCurrentUserUnAssignedDevices: (access_token: string, userId: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/device/unassigned/${userId}`,
      access_token
    );
  },
  transferDevices: (access_token: string, data: Object) => {
    return requests.postWithToken(
      `${apiUrl}/api/device/transfer`,
      data,
      access_token
    );
  }
};

const Dashboard = {
  getReportData: (access_token: string) => {
    return requests.getWithToken(`${apiUrl}/api/dashboard`, access_token);
  },
};

const Zone = {
  getZoneData: (access_token: string) => {
    return requests.getWithToken(`${apiUrl}/odata/zonelist?$count=true&$orderby=zoneName asc`, access_token);
  },
  create: (access_token: string, data: string) => {
    return requests.postWithToken(`${apiUrl}/api/zone/create`, data, access_token)
  },
  update: (access_token: string, data: string) => {
    return requests.postWithToken(`${apiUrl}/api/zone/update`, data, access_token)
  },
  getAssignableZones: (access_token: string, searchValue: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/zone/assignable?key=${searchValue}`,
      access_token
    );
  },
};

const Product = {
  getProductData: (access_token: string) => {
    return requests.getWithToken(`${apiUrl}/odata/productlist?$count=true&$orderby=productName asc`, access_token);
  },
  create: (access_token: string, data: string) => {
    return requests.postWithToken(`${apiUrl}/api/product/create`, data, access_token)
  },
  update: (access_token: string, data: string) => {
    return requests.postWithToken(`${apiUrl}/api/product/update`, data, access_token)
  },
  getAssignableProducts: (access_token: string, searchValue: string) => {
    return requests.getWithToken(
      `${apiUrl}/api/product/assignable?key=${searchValue}`,
      access_token
    );
  },
};

export default {
  Auth,
  Organisation,
  User,
  Device,
  Request,
  Approval,
  Dashboard,
  Zone,
  Product
};
