export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const OPEN_APPROVAL_ACTION_MODAL = "OPEN_APPROVAL_ACTION_MODAL";
export const CLOSE_APPROVAL_ACTION_MODAL = "CLOSE_APPROVAL_ACTION_MODAL";
export const SET_APPROVALS_PENDING = "SET_APPROVALS_PENDING";
export const SET_APPROVALS_LIST = "SET_APPROVALS_LIST";
export const SUBMIT_APPROVAL_ACTION_PENDING = "SUBMIT_APPROVAL_ACTION_PENDING";
export const SUBMIT_APPROVAL_ACTION_SUCCESS = "SUBMIT_APPROVAL_ACTION_SUCCESS";
export const SUBMIT_APPROVAL_ACTION_ERROR = "SUBMIT_APPROVAL_ACTION_ERROR";
export const CLEAR_SUBMIT_APPROVAL_ACTION_ERROR =
  "CLEAR_SUBMIT_APPROVAL_ACTION_ERROR";
