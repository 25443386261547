import { RequestState } from "../../types/request";
import {
  SET_REQUESTS_LIST,
  SET_REQUESTS_PENDING,
} from "../constants/request.constants"

const initialState: RequestState = {
  isGetRequestsPending: false,
  requests: [],
};

export default (
  localState: RequestState = initialState,
  action: Action
): RequestState => {
  switch (action.type) {
    case SET_REQUESTS_PENDING: {
      return {
        ...localState,
        isGetRequestsPending: true,
      };
    }
    case SET_REQUESTS_LIST: {
      const { requestsOdata } = action.payload;
      return {
        ...localState,
        isGetRequestsPending: false,
        requests: requestsOdata && requestsOdata.value,
      };
    }
    default: {
      return localState;
    }
  }
};
