import moment from "moment";
import agent from "../../services/agent";
import {
  clearEnableUserError,
  clearLoginUserError,
  enableUserError,
  enableUserPending,
  enableUserPendingError,
  enableUserPendingSuccess,
  loginUserError,
  loginUserPending,
  loginUserPendingError,
  loginUserPendingSuccess,
  loginUserSuccess,
} from "./login.actions";
import {
  SET_CURRENT_PROFILE,
  SET_FORGOT_PASSWORD_CONFIRMATION_STATUS,
} from "../constants/profile.constants";
import { ProfileState } from "../../types/profile";
import { clearForm, updateForm } from "./form.actions";
import { showSnackbarStatus } from "./snackbar.actions";

export const setTokenDetails = (authData: any) => {
  const { access_token, expires_in, token_type } = authData;
  const expires = moment().unix() + expires_in;
  localStorage.setItem("token", access_token);
  // set the expired time as (now + value from server)
  localStorage.setItem("expires_in", expires);
  localStorage.setItem("token_type", token_type);
};

const setCurrentProfile = (currentProfile: ProfileState): Action => ({
  type: SET_CURRENT_PROFILE,
  payload: currentProfile,
});

export const setForgotPasswordConfirmationStatus = (status: string): Action => ({
  type: SET_FORGOT_PASSWORD_CONFIRMATION_STATUS,
  payload: status,
});

export const userLogin = () => (dispatch: Function, getState: Function) => {
  const currentState = getState();
  const { formLogin } = currentState && currentState.form;

  if (!formLogin) {
    dispatch(loginUserError("Please fill required details."));
    return;
  }

  dispatch(loginUserPending());
  dispatch(clearLoginUserError());
  agent.Auth.login(formLogin.email, formLogin.password)
    .then((authData) => {
      setTokenDetails(authData);
      const { access_token, expires_in } = authData;
      agent.Auth.getCurrentUser(access_token)
        .then((currentUser) => {
          dispatch(loginUserPendingSuccess());
          dispatch(setCurrentProfile(currentUser));
          dispatch(
            loginUserSuccess(currentUser.email, access_token, expires_in)
          );
        })
        .catch((err) => {
          dispatch(loginUserPendingError());
          console.log("Get Current User server error", err);
        });
    })
    .catch((err) => {
      dispatch(loginUserPendingError());
      if (err && err.response) {
        const { error } = err.response.body;
        dispatch(loginUserError(error));
      } else {
        console.log("User login server error", err);
      }
    });
};

export const enableUserAccount =
  (code: string) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { formCompleteRegistration } = currentState && currentState.form;

    if (!formCompleteRegistration) {
      dispatch(enableUserError("Please fill required details."));
      return;
    }

    dispatch(enableUserPending());
    dispatch(clearEnableUserError());
    agent.Auth.enableAccount(formCompleteRegistration, code)
      .then((result) => {
        dispatch(
          updateForm({
            formLogin: {
              email: result,
              password: formCompleteRegistration.password,
            },
          })
        );
        dispatch(userLogin());
        dispatch(enableUserPendingSuccess());
        dispatch(clearForm());
      })
      .catch((err) => {
        dispatch(enableUserPendingError());
        if (err && err.response) {
          const { message } = err.response.body;
          dispatch(enableUserError(message));
          return;
        }
        console.log("enable user server error", err);
        dispatch(enableUserError(null));
        dispatch(clearForm());
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  };

export const forgotPassword = (email: string) => (dispatch: Function) => {
  dispatch(setForgotPasswordConfirmationStatus("INPROGRESS"));
  agent.Auth.forgotPassword(email)
    .then((result) => {
      dispatch(clearForm());
      dispatch(setForgotPasswordConfirmationStatus("EMAIL_SENT"));
    })
    .catch((err) => {
      dispatch(setForgotPasswordConfirmationStatus("INVALID_EMAIL"));
    });
};

export const resetPassword =
  (code: string) => (dispatch: Function, getState: Function) => {
    const currentState = getState();
    const { formResetPassword } = currentState && currentState.form;

    if (!formResetPassword) {
      dispatch(enableUserError("Please fill required details."));
      return;
    }

    dispatch(clearEnableUserError());
    dispatch(enableUserPending());
    agent.Auth.resetPassword(formResetPassword, code)
      .then((result) => {
        dispatch(
          updateForm({
            formLogin: {
              email: result,
              password: formResetPassword.password,
            },
          })
        );
        dispatch(userLogin());
        dispatch(clearForm());
        dispatch(enableUserPendingSuccess());
      })
      .catch((err) => {
        dispatch(enableUserPendingError());
        if (err && err.response) {
          const { message } = err.response.body;
          dispatch(enableUserError(message));
          return;
        }
        console.log("reset password error", err);
        dispatch(enableUserError(null));
        dispatch(clearForm());
        dispatch(
          showSnackbarStatus("Something went wrong. Please try again later")
        );
      });
  };
